import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import { graphql } from 'gatsby';
import { rem } from 'polished';

import ArrowButton from './ArrowButton';
import Button from './Button';
import { P, Headline } from './Typography';

import { colors, breakpoints, fontSizes } from '../helpers/variables';
import { vwMinMax, mq } from '../helpers/stylehelpers';
import { fluidImageType } from '../helpers/prop-types';

/**
 * Breakpoint für die Komponente Mobile <-> Desktop
 * @type {Number}
 */
const elementBreakpoint = 870;

/**
 * Vertikale Offset in Pixeln basierend auf den Designviewport 1920
 * @type {Number}
 */
const verticalOffset = 30;

/**
 * Wrapper für die Homestage
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
    /* ${vwMinMax('margin-bottom', 80, 90, 0, elementBreakpoint)}; */
    /* ${vwMinMax('margin-bottom', 120, 180, elementBreakpoint)}; */
    background-color: ${colors.primary};
    position: relative;

    &:after {
        ${mq.large`
            display: block;
            content: '';
            background-color: ${({ fillBg }) => (fillBg ? colors[fillBg] : colors.white)};
            height: ${rem(verticalOffset)};
        `}
    }
`;

/**
 * ImageWrapper um alle Bilder herum
 * @type {StyledComponent}
 */
const ImageWrapper = styled.div`
    background: ${colors.white};
    position: relative;
    width: 100%;
    z-index: 2;

    &:before {
        content: '';
        display: block;
        padding-top: 63.33333%;
        width: 100%;
    }

    @media (min-width: ${elementBreakpoint}px) {
        transform: translateX(34vw);
        width: 66vw;
    }

    ${mq.xxlarge`
        top: ${rem(verticalOffset)};
        transform: translateX(calc(34vw - ${rem(60)}));
    `};

    ${mq.xxxlarge`
       transform: translateX(calc(34vw - ${rem(80)}));
    `}
`;

/**
 * Image-Komponente der Homestage
 * @type {GatsbyImage}
 */
const HomestageImg = styled(Img)`
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    top: 0;
    transition: opacity 0.75s;
    width: 100%;
`;

/**
 * Wrapper für den Text der Stage
 * @type {StyledComponent}
 */
const Text = styled.div`
    @media (min-width: ${elementBreakpoint}px) {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: stretch;
        height: 100%;
        z-index: 1;
    }
`;

/**
 * Innerer Wrapper für den Text
 * @type {StyledComponent}
 */
const TextInner = styled.div`
    color: ${colors.white};
    padding: 2.2em 1em 2em;
    text-align: center;

    @media (min-width: ${elementBreakpoint}px) {
        padding: ${rem(15)};
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: calc(34vw);
    }
    ${mq.large`
        padding: ${rem(30)} ${rem(40)};
        padding-left: ${rem(40)};
        width: calc(34vw - ${rem(40)});
    `};

    ${mq.xxlarge`
        padding: ${rem(30)} ${rem(60)};
        width: calc(34vw - ${rem(60)});
    `};

    ${mq.xxxlarge`
        padding: ${rem(30)} ${rem(80)};
        width: calc(34vw - ${rem(80)});
    `};
`;

/**
 * Headline innerhalb der ImageBox
 * @type {StyledComponent}
 */
const Title = styled(Headline)`
    text-transform: uppercase;
    font-size: ${rem(30)};
    color: ${colors.white};
    margin: 0;

    @media (min-width: ${elementBreakpoint}px) {
        font-size: ${rem(25)};
    }

    ${mq.xlarge`
        font-size: ${rem(32)};
    `};

    ${mq.xxlarge`
        font-size: ${rem(35)};
    `};

    ${mq.xxxlarge`
        font-size: ${rem(37)};
    `};

    @media (min-width: 1920px) {
        font-size: ${rem(46)};
    }
`;

/**
 * TeaserContent
 * @type {StyledComponent}
 */
const Content = styled(P)`
    ${fontSizes.m};

    ${mq.xxlarge`
        ${fontSizes.l};
    `};
`;

/**
 * Wrapper für die Arrow-Buttons
 * @type {StyledComponent}
 */
const UIWrapper = styled.div`
    ${vwMinMax('width', 80, 180, breakpoints.medium)};
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 3;

    @media (max-width: ${elementBreakpoint - 1}px) {
        left: 50%;
        margin-top: 63.333%;
        top: 0;
        right: auto;
        bottom: auto;
        transform: translate(-50%, -50%);
    }

    @media (min-width: ${elementBreakpoint}px) {
        bottom: auto;
        display: flex;
        height: auto;
        left: 5%;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        width: 90%;
    }
`;

const Swipeable = ({ changeImage, children }) => {
    const handlers = useSwipeable({
        onSwiped: (evt, deltaX) => {
            if (deltaX > 0) {
                changeImage('next');
            } else {
                changeImage('prev');
            }
        },
    });

    // eslint-disable-next-line
    return <div {...handlers}>{children}</div>;
};

Swipeable.propTypes = {
    changeImage: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

/**
 * HomeStage
 * @extends React
 */
class HomeStage extends React.Component {
    /**
     * AutoplayInterval
     * @type {Function}
     */
    autoplayInterval = null;

    /**
     * AutoplaySpeed
     * @type {Number}
     */
    autoplaySpeed = 3000;

    constructor(props) {
        super(props);
        this.state = {
            activeImage: 0,
        };
    }

    /**
     * Handler, wenn Komponente im DOM hängt
     * @method componentDidMount
     */
    componentDidMount() {
        const { autoplay } = this.props;
        if (autoplay) {
            this.autoplayInterval = setInterval(() => {
                this.changeImage('next');
            }, this.autoplaySpeed);
        }
    }

    /**
     * Handler, wenn Komponente aus DOM fliegt
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        if (this.autoplayInterval) {
            clearInterval(this.autoplayInterval);
        }
    }

    /**
     * Listener für den Klick auf die ArrowButtons
     * @param  {MouseEvent} evt Klick-Event
     */
    onArrowButtonClick = evt => {
        const direction = evt.target.getAttribute('direction');
        // Autoplay killen, wenn Klick erfolgt ist
        if (this.autoplayInterval) {
            clearInterval(this.autoplayInterval);
        }
        this.changeImage(direction);
    };

    /**
     * Wechselt das Bild der Homestage, in dem es den State manipuliert
     * @param  {String} direction "Richtung" des Bildwechsels
     */
    changeImage = direction => {
        const { activeImage } = this.state;
        const { images } = this.props;
        const imageCount = images.length - 1;
        let nextImageIndex;

        if (direction === 'next') {
            if (activeImage + 1 <= imageCount) {
                nextImageIndex = activeImage + 1;
            } else if (activeImage + 1 > imageCount) {
                nextImageIndex = 0;
            }
        } else if (direction === 'prev') {
            if (activeImage - 1 >= 0) {
                nextImageIndex = activeImage - 1;
            } else if (activeImage - 1 < 0) {
                nextImageIndex = imageCount;
            }
        }

        this.setState({
            activeImage: nextImageIndex,
        });
    };

    /**
     * Render
     * @return {JSX} HTML-Code der Homestage
     */
    render() {
        const { images, fillBg } = this.props;
        const { activeImage } = this.state;
        const imageElements = images.map((image, index) => (
            <HomestageImg
                visible={index === activeImage}
                key={image.node.childImageSharp.fluid.src}
                fluid={image.node.childImageSharp.fluid}
                style={{ position: 'absolute', zIndex: 0 }}
                alt="Schütz Dienstleistungen"
            />
        ));

        return (
            <Wrapper fillBg={fillBg}>
                <ImageWrapper>
                    {images.length > 1 && (
                        <UIWrapper>
                            <ArrowButton onClick={this.onArrowButtonClick} direction="prev" />
                            <ArrowButton
                                toRight
                                onClick={this.onArrowButtonClick}
                                direction="next"
                            />
                        </UIWrapper>
                    )}
                    <Swipeable changeImage={this.changeImage}>{imageElements}</Swipeable>
                </ImageWrapper>
                <Text>
                    <TextInner>
                        <Title level="h1">Schütz Dienstleistungen</Title>
                        <Content>
                            Wir sind Ihr kompetenter Partner bei Dienstleistungen rund ums Haus.
                        </Content>
                        <Button to="/leistungen" whiteGhost>
                            Unsere Leistungen
                        </Button>
                    </TextInner>
                </Text>
            </Wrapper>
        );
    }
}

export default HomeStage;

/**
 * Prop Types
 * @type {Object}
 */
HomeStage.propTypes = {
    images: PropTypes.arrayOf(fluidImageType).isRequired,
    autoplay: PropTypes.bool,
    fillBg: PropTypes.oneOf(['gray100']),
};

/**
 * DefaultProps
 * @type {Object}
 */
HomeStage.defaultProps = {
    autoplay: false,
    fillBg: null,
};

/**
 * GraphQL Fragment für GatsbyImage
 * @type {GraphQL}
 */
// eslint-disable-next-line no-undef
export const query = graphql`
    fragment HomeStageFluid on File {
        childImageSharp {
            fluid(maxWidth: 1680, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;
