import React from 'react';
import styled, { css } from 'styled-components';
import { position } from 'polished';
import PropTypes from 'prop-types';
import { Swipeable } from 'react-swipeable';

import ArrowButton from './ArrowButton';
import { remCalc, mq, vwMinMax } from '../helpers/stylehelpers';
import { gapable } from '../helpers/traits';
import { breakpoints, colors } from '../helpers/variables';
import { childrenType } from '../helpers/prop-types';

/**
 * Wrapper für den Slider
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
    ${gapable()};
    ${vwMinMax('max-width', 650, 1230, breakpoints.medium)};
    position: relative;
    width: 100%;
    margin: 0 auto;
`;

/**
 * Innerer Bereich für den Slider
 * @type {StyledComponent}
 */
const Inner = styled.div`
    overflow: hidden;
    position: relative;
`;

/**
 * Flex Container, um die Slider-Items nebeneinander zu stellen
 * @type {StyledComponent}
 */
const ItemWrapper = styled.div`
    display: flex;
    transform: translateX(${({ activeItem }) => `-${activeItem * 100.5}%`});
    transition: 0.25s transform;
`;

/**
 * Slider Item
 * @type {StyledComponent}
 */
const Item = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    width: 100.5%;
`;

/**
 * Gemeinsame Styles für die Arrow-Buttons
 * @type {CSSString}
 */
const buttonStyle = css`
    position: absolute;
    height: ${remCalc(40)};
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: ${remCalc(40)};

    ${mq.medium`
        height: ${remCalc(48)};
        width: ${remCalc(48)};
    `};
`;

/**
 * Button Links
 * @type {StyledComponent}
 */
const ButtonLeft = styled(ArrowButton)`
    ${buttonStyle};
    left: 0;
    ${mq.mediumDown`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    `};
    ${mq.large`
        transform: translate(-50%, -50%);
    `};

    ${({ specialArrowStyles }) => specialArrowStyles && specialArrowStyles};
`;

/**
 * Button Rechts
 * @type {StyledComponent}
 */
const ButtonRight = styled(ArrowButton)`
    ${buttonStyle};
    right: 0;
    ${mq.mediumDown`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    `};
    ${mq.large`
        transform: translate(50%, -50%);
    `};
    ${({ specialArrowStyles }) => specialArrowStyles && specialArrowStyles};
`;

const Counter = styled.div`
    ${vwMinMax('font-size', 15, 20)};
    ${position('absolute', null, null, 0, '50%')}
    background-color: ${colors.white};
    margin: 0 auto;
    max-width: ${remCalc(100)};
    padding: 0.45em 1em;
    text-align: center;
    transform: translate(-50%, 0);
    z-index: 2;
`;

/**
 * Slider Komponente
 * @class
 */
class Slider extends React.Component {
    /**
     * Prop Types
     * @type {Object}
     */
    static propTypes = {
        children: childrenType.isRequired,
        className: PropTypes.string,
        gap: gapable.propType,
        hideCounter: PropTypes.bool,
        counterType: PropTypes.string,
        specialArrowStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    };

    /**
     * Default Props
     * @type {Object}
     */
    static defaultProps = {
        className: null,
        gap: null,
        hideCounter: false,
        counterType: 'numbers',
        specialArrowStyles: null,
    };

    /**
     * State
     * @type {Object}
     */
    state = {
        activeItem: 0,
        disablePrev: true,
        disableNext: false,
    };

    /**
     * Listener für den Klick auf die ArrowButtons
     * @param  {MouseEvent} evt Klick-Event
     */
    onArrowButtonClick = evt => {
        const direction = evt.target.getAttribute('direction');
        this.changeImage(direction);
    };

    /**
     * Listener für Swipe
     * @param  {SwipeEvent} evt    Swipe-Event
     * @param  {Number} deltaX Swipe-Distanz in X-Richtung
     */
    onSwiped = (evt, deltaX) => {
        if (deltaX > 0) {
            this.changeImage('next');
        } else {
            this.changeImage('prev');
        }
    };

    /**
     * Wechselt das Bild der Homestage, in dem es den State manipuliert
     * @param  {String} direction "Richtung" des Bildwechsels
     */
    changeImage = direction => {
        const { activeItem } = this.state;
        const { children } = this.props;
        const itemCount = children.length - 1;
        let disablePrev = false;
        let disableNext = false;
        let nextItem;

        if (direction === 'next') {
            if (activeItem + 1 <= itemCount) {
                nextItem = activeItem + 1;
            }
            if (activeItem + 1 >= itemCount) {
                disableNext = true;
                nextItem = itemCount;
            }
        } else if (direction === 'prev') {
            if (activeItem - 1 >= 0) {
                nextItem = activeItem - 1;
            }
            if (activeItem - 1 <= 0) {
                disablePrev = true;
                nextItem = 0;
            }
        }

        this.setState({
            activeItem: nextItem,
            disableNext,
            disablePrev,
        });
    };

    /**
     * Render
     * @return {JSX} HTML-Code der Homestage
     */
    render() {
        const { activeItem, disablePrev, disableNext } = this.state;
        const {
            children,
            className,
            gap,
            hideCounter,
            counterType,
            specialArrowStyles,
        } = this.props;

        const items = children.length ? (
            children.map((item, index) => (
                <Item key={item.key} number={index}>
                    {item}
                </Item>
            ))
        ) : (
            <Item>{children}</Item>
        );

        return (
            <Wrapper className={className} gap={gap}>
                {children.length > 1 && (
                    <>
                        <ButtonLeft
                            onClick={this.onArrowButtonClick}
                            direction="prev"
                            disabled={disablePrev}
                            specialArrowStyles={specialArrowStyles}
                        />
                        <ButtonRight
                            toRight
                            onClick={this.onArrowButtonClick}
                            direction="next"
                            disabled={disableNext}
                            specialArrowStyles={specialArrowStyles}
                        />
                    </>
                )}
                {!hideCounter && children.length > 1 && counterType === 'numbers' && (
                    <Counter>
                        {/* eslint-disable-next-line */}
                        {activeItem + 1} von {children.length}
                    </Counter>
                )}
                <Inner>
                    <Swipeable onSwiped={this.onSwiped}>
                        <ItemWrapper activeItem={activeItem}>{items}</ItemWrapper>
                    </Swipeable>
                </Inner>
            </Wrapper>
        );
    }
}

export default Slider;
