import styled from 'styled-components';
import { position } from 'polished';

import { vwMinMax, pseudoStateClasses } from '../helpers/stylehelpers';
import { colors, hoverSpeed, breakpoints } from '../helpers/variables';

import arrowLeft from '../images/icons/arrow-left.svg';
import arrowRight from '../images/icons/arrow-right.svg';

const ArrowButton = styled.div`
    ${vwMinMax('width', 35, 60, breakpoints.medium)};
    ${vwMinMax('height', 35, 60, breakpoints.medium)};
    background: ${colors.primary} url(${({ toRight }) => (toRight ? arrowRight : arrowLeft)});
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-position: ${({ toRight }) => (toRight ? `56%` : `43%`)} center;
    border-radius: 100%;
    background-size: 30%;
    display: block;
    cursor: pointer;
    flex-shrink: 0;
    overflow: hidden;
    transition: background-color ${hoverSpeed};
    ${pseudoStateClasses`
        background-color: ${colors.primary};
    `};

    ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed;
        pointer-events: none;
        &:before {
            ${position({ top: 0, left: 0 })};
            content: '';
            background-color: ${colors.white};
            opacity: 0.45;
            display: block;
            width: 100%;
            height: 100%;
        }
    `};
`;

export default ArrowButton;
