import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { css } from 'styled-components';

import Layout from '../components/Layout';
import HomeStage from '../components/HomeStage';
import Section from '../components/Section';
import TextImgBox from '../components/TextImgBox';
import ContactTeaser from '../components/ContactTeaser';
// import Container from '../components/Container';
import { Headline, P } from '../components/Typography';
import FullwidthTeaser from '../components/FullwidthTeaser';
import Slider from '../components/Slider';
import ReferenceSliderItem from '../components/ReferenceSliderItem';
import Intro from '../components/Intro';
import Button from '../components/Button';
// import NewsBox from '../components/NewsBox';
import { getImageFromNode } from '../helpers/utils';

/**
 * Die Komponente der Startseite
 * @param {Object} data Daten aus der GraphQL-Query
 */
const StartPage = ({ data }) => {
    const { textImgBoxImages, flooringImage, aboutImage } = data;
    return (
        <Layout title="Startseite" description="Schütz Dienstleistungen - rund ums Haus">
            <HomeStage images={data.homeStageImages.edges} autoplay fillBg="gray100" />
            <Section bg="gray" container="l">
                <Headline level="h2" textAlign="center" gap="xxl">
                    Aufgaben, die wir für Sie lösen können
                </Headline>
                <TextImgBox
                    headline="Gebäudereinigung & Wartung"
                    gap="xxxl"
                    content="Wir wollen, dass Sie sich im Büro, in Ihrem Unternehmen oder bei Ihnen Zuhause rundum wohl fühlen. Deshalb tragen wir mit Reinigung und Pflege dazu bei, Ihre Materialien dauerhaft zu erhalten."
                    button={{ text: 'Mehr erfahren', to: '/' }}
                    image={
                        getImageFromNode(textImgBoxImages, 'start/textimgbox-gebaeudereinigung.jpg')
                            .node.childImageSharp
                    }
                />
                <TextImgBox
                    headline="Schädlingsbekämpfung & Desinfektion"
                    gap="xxl"
                    reverse
                    content="Besonders Betriebe in der Lebensmittelwirtschaft, Gastronomie und der Gemeinschaftsverpflegung sind in hohem Maße gefährdet."
                    button={{ text: 'Mehr erfahren', to: '/' }}
                    image={
                        getImageFromNode(
                            textImgBoxImages,
                            'start/textimgbox-schaedlingsbekaempfung.jpg'
                        ).node.childImageSharp
                    }
                />
                <TextImgBox
                    headline="Schulung & Beratung"
                    content="Im Bereich der Hygiene verfügen wir über fundiertes Fachwissen. Dieses Wissen geben wir gerne weiter und bieten Ihnen eine umfassende Beratung."
                    button={{ text: 'Mehr erfahren', to: '/' }}
                    image={
                        getImageFromNode(textImgBoxImages, 'start/textimgbox-schulung.jpg').node
                            .childImageSharp
                    }
                />
            </Section>
            <ContactTeaser />
            {/* eslint-disable react/jsx-wrap-multilines */}
            <FullwidthTeaser reverse image={flooringImage.childImageSharp}>
                <Intro
                    headline="Bodenverlegung & Reparaturen"
                    headlineLevel="h3"
                    cta={
                        <Button to="/leistungen#bodenverlegung-und-reparaturen">
                            Mehr erfahren
                        </Button>
                    }
                >
                    <P>
                        Schütz Dienstleistungen bietet Ihnen abseits des Gebäudereinigungs- und
                        Hygienesektors auch fachgerechte Verlegung, Reparatur und Pflege von
                        Bodenbelägen.
                    </P>
                </Intro>
            </FullwidthTeaser>

            <FullwidthTeaser image={aboutImage.childImageSharp}>
                <Intro
                    headline={
                        <span>
                            Über uns –
                            <br />
                            wer sind wir eigentlich?
                        </span>
                    }
                    cta={<Button to="/ueber-uns">Mehr über uns</Button>}
                >
                    <P>
                        {/* eslint-disable */}
                        Wir sind ihr kompetenter und professioneller Partner für eine Vielzahl von
                        Dienstleistungen rund ums Haus. Mit unser über{' '}
                        {` ${new Date().getFullYear() - 1992}`}-jährigen Erfahrung und zahlreichen
                        Zertifizierungen stehen wir gerne auch Ihnen tatkräftig bei individuellen
                        Anforderungen und Wünschen zur Seite.
                        {/* eslint-enable */}
                    </P>
                </Intro>
            </FullwidthTeaser>
            {/* eslint-enable react/jsx-wrap-multilines */}
        </Layout>
    );
};

StartPage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

export default StartPage;

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        homeStageImages: allFile(
            filter: { relativePath: { glob: "start/homestage-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    ...HomeStageFluid
                }
            }
        }
        textImgBoxImages: allFile(
            filter: { relativePath: { glob: "start/textimgbox-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    ...TextImgBoxFluid
                }
            }
        }
        flooringImage: file(relativePath: { glob: "start/bodenverlegung.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 650, quality: 80, toFormat: JPG) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aboutImage: file(relativePath: { glob: "start/ueber.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        sliderImages: allFile(
            filter: { relativePath: { glob: "references/reference-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    ...SliderImageFluid
                }
            }
        }
    }
`;
