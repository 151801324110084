import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';

import Section from './Section';
import Container from './Container';
import { fluidImageType } from '../helpers/prop-types';

import { globalFramePadding, mq } from '../helpers/stylehelpers';

/** Angepasste Section */
const StyledSection = styled(Section)`
    &,
    &:last-of-type {
        padding: 0;
    }
`;

/** Angepasster Container */
const StyledContainer = styled(Container)`
    align-items: center;
`;

/** Wrapper um den Content herum */
const ContentWrapper = styled.div`
    ${globalFramePadding};
    padding-top: ${rem(20)};
    padding-bottom: ${rem(30)};

    margin-top: 0 !important;

    ${mq.medium`
        padding-top: ${rem(30)};
        padding-bottom: ${rem(40)};
    `};

    ${mq.large`
        padding-top: ${rem(50)};
        padding-bottom: ${rem(60)};
    `};
`;

/** Container ums Bild rum */
const ImageWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

/** Das eigentliche Bild */
const Img = styled(GatsbyImage)`
    height: 100%;
`;

/**
 * Erzeugt einen Teaser, der mit Bild und Text über die ganze breite geht
 * @param { node } props.children Der Content
 * @param { fluidImageType } props.image Das Bild
 * @param { boolean } props.reverse Ob die Aufteilung halb oder 2/3 sein soll
 */
const FullwidthTeaser = ({ children, image, reverse }) => (
    <StyledSection bg="gray" className="fullwidth-teaser">
        <StyledContainer grid cols={2} reverseOnMobile={!reverse}>
            {reverse ? (
                <>
                    <ImageWrapper>
                        <Img fluid={image.fluid} />
                    </ImageWrapper>
                    <ContentWrapper>{children}</ContentWrapper>
                </>
            ) : (
                <>
                    <ContentWrapper>{children}</ContentWrapper>
                    <ImageWrapper>
                        <Img fluid={image.fluid} />
                    </ImageWrapper>
                </>
            )}
        </StyledContainer>
    </StyledSection>
);

FullwidthTeaser.propTypes = {
    children: PropTypes.node.isRequired,
    image: fluidImageType.isRequired,
    reverse: PropTypes.bool,
};

FullwidthTeaser.defaultProps = {
    reverse: false,
};

export default FullwidthTeaser;
