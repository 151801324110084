import React from 'react';
import styled from 'styled-components';
import { rem, rgba } from 'polished';

import Section, { sectionPadding } from './Section';
import Container from './Container';
import LinkWrapper from './LinkWrapper';
import Icon from './Icon';
import { Headline } from './Typography';

import { colors } from '../helpers/variables';
import { mq } from '../helpers/stylehelpers';

/** Angepasste Section */
const Wrapper = styled(Section)`
    /* Wenn Direkt über Footer, diesen anpassen */
    + footer {
        background-color: ${colors.white};
        > *,
        a,
        a:hover {
            color: ${colors.black};
        }
    }

    &,
    &:last-of-type {
        ${sectionPadding};
    }
`;

/** Angepasste Headline */
const StyledHeadline = styled(Headline)`
    text-transform: uppercase;
`;

/** Angepasstes Icon */
const ContactIcon = styled(Icon)`
    display: block;
    color: ${colors.white};
    font-size: ${rem(40)};
    margin-bottom: 0.35em;
`;

/** Der eigentliche Link zur Kontaktmöglichkeit */
const ContactType = styled(LinkWrapper)`
    display: flex;
    color: ${colors.white};
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
`;

/** Trenner */
const Seperator = styled.div`
    background-color: ${rgba(colors.white, 0.5)};
    height: 1px;
    margin: 1em 0;
    width: 100%;
    ${mq.medium`
        background-color: ${colors.white};
        height: auto;
        margin: 0 ${rem(45)};
        width: 1px;
    `};
`;

/**
 * Erzeugt den Contact-Teaser
 */
const ContactTeaser = () => (
    <Wrapper bg="primary" textAlign="center" container="m" className="contact-teaser">
        <StyledHeadline level="h3" textColor="white" gap="xxl">
            Sie haben Fragen oder benötigen Hilfe?
            <br />
            Melden Sie sich bei uns!
        </StyledHeadline>
        <Container display="flex" size="m" justify="center">
            <ContactType to="tel:07127 / 50311">
                <ContactIcon type="phone" />
                07127 / 50311
            </ContactType>
            <Seperator />
            <ContactType to="/kontakt">
                <ContactIcon type="mail" />
                Kontaktformular
            </ContactType>
        </Container>
    </Wrapper>
);

export default ContactTeaser;
