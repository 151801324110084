import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';

import { textAlignable, gapable } from '../helpers/traits';
import { fontSizes } from '../helpers/variables';

import { Headline } from './Typography';

/**  Wrapper */
const Wrapper = styled.div`
    max-width: ${rem(850)};
    margin-left: auto;
    margin-right: auto;
    ${gapable()};
    ${textAlignable('left')};
`;

/** Content als div */
const Content = styled.div`
    ${gapable()};
    ${fontSizes.s};

    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

/** Button */
const Cta = styled.div`
    margin-top: 0.75em;
`;

/**
 * Erzeugt ein Intro
 *
 * @param {string} props.className Optional: ClassName für erweitertes Styling
 * @param {string} props.children  Copytext / Inhalt des Intros
 * @param {string} props.cta Das Link-Ziel
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.textAlign Optional: Textausrichtung
 * @param {string} props.headline Headline des Intros
 * @param {string} props.headlineLevel Level der Headline
 *
 * @example <Intro  textAlign="inline-block">...</Intro>
 */
const Intro = ({ className, headline, children, cta, gap, textAlign, headlineLevel }) => (
    <Wrapper className={className} textAlign={textAlign} gap={gap}>
        <Headline level={headlineLevel} gap="m">
            {headline}
            {/* <span dangerouslySetInnerHTML={{ __html: headline }} /> */}
        </Headline>
        <Content>{children}</Content>
        {cta && <Cta>{cta}</Cta>}
    </Wrapper>
);

Intro.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    cta: PropTypes.node,
    gap: gapable.propType,
    textAlign: textAlignable.propType,
    headlineLevel: PropTypes.string,
    headline: PropTypes.node.isRequired,
};

Intro.defaultProps = {
    className: null,
    headlineLevel: 'h2',
    cta: null,
    gap: null,
    textAlign: 'left',
};

export default Intro;
